<template>
    <form @submit.prevent="submitHandler">
        <recess-modal :show-modal="isModalVisible"
            @close="close"
        >
            <template slot="modal-title">
                <h3>{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.Title }} {{ orderNumber }}</h3>

            </template>

            <template slot="modal-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
                    <label class="w-30">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Label }}</label>
                    <recess-multi-select
                        :selected-options="selectedLegalCompanyId"
                        :options="legalCompanyOptions"
                        :search-input="true"
                        :single-select="true"
                        class="w-70 qa-legal-company-select"
                        :placeholder="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompany.Placeholder"
                        @input="(newValue) => legalCompanySelectHandler(newValue)"
                        @close="onTouch"
                    />
                    <p v-if="!isAdministrator" 
                        class="mt-3 font-italic"
                    >
                        {{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartOne }}
                        <recess-button
                            :title="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.Title"
                            variant="variant4"
                            class="mb-4"
                            url="/leveranciersgegevens/handelsnamen"
                        />
                        {{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.LegalCompanyButton.PartTwo }}
                    </p>
                </div>

                <!-- if user selects a LC, we get LC by id and prefill the values in the input fields -->
                <template v-if="selectedLegalCompanyId && legalCompanyDetails">
                    <legal-company-detail :legal-company-details="legalCompanyDetails" />
                </template>

				<h6 class="u-fw-semi-bold mt-5">{{ PROVIDER.Order.OrderOverview.Popup.ApproveOrder.PriceSectionTitle }}</h6>
			
				<div class="row">
					<recess-multi-select
						:selected-options="selectedCountry"
						:options="countryOptions"
						:search-input="true"
						:single-select="true"
						:label-text="PROVIDER.Order.OrderOverview.Popup.ApproveOrder.VatCountry.Label"
						:error-message="vatCountryError"
						class="col-3 qa-purchase-agreement-vat-country"
						@input="(newValue) => populateVatOptions(newValue)"
					/>

					<!-- show vatPercentage as dropdown if vatPercentageOptions is not null-->
					<recess-multi-select
						v-if="vatPercentageOptions.length"
						:selected-options="selectedVatPercentage"
						:options="vatPercentageOptions"
						:single-select="true"
						:label-text="`${PROVIDER.Order.OrderOverview.Popup.ApproveOrder.VatPercentage.Label}*`"
						:error-message="vatPercentageError"
						class="col-3 qa-purchase-agreement-vat-percentage"
						@input="(newValue) => onVatPercentageChange(newValue)"
					/>

					<!-- show vatPercentage as input field if vatPercentageOptions is null -->
					<recess-input
						v-if="!vatPercentageOptions.length"
						:value="selectedVatPercentage"
						:label-text="`${PROVIDER.Order.OrderOverview.Popup.ApproveOrder.VatPercentage.Label}*`"
						:placeholder-text="PROVIDER.Portfolio.CourseCreate.Form.InvoiceItem.VatPercentage.Placeholder"
						:error-message="vatPercentageError"
						class="col-3 qa-course-vat-percentage-input" 
						@input="(newValue) => onVatPercentageChange(newValue)"
					/>

					<div class="col-6 d-flex justify-content-end ">
						<div class="d-flex mr-4 flex-column qa-invoice-vat-amount-value">
							<div class="c-recess-input__label">{{ PROVIDER.Order.OrderOverview.Popup.ApproveOrder.PriceBeforeTax.Label }}</div>
							<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ this.order?.orderLines[0].subtotalWithoutVAT | currency }}</p>
						</div>
						<div class="d-flex mr-4 flex-column qa-invoice-vat-amount-value">
							<div class="c-recess-input__label text-right">{{ PROVIDER.Order.OrderOverview.Popup.ApproveOrder.VatAmount.Label }}</div>
							<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ Number(getVatAmount) | currency }}</p>
						</div>
	
						<div class="d-flex flex-column qa-invoice-price-with-tax-value">
							<div class="c-recess-input__label p-0">{{ PROVIDER.Order.OrderOverview.Popup.ApproveOrder.PriceWithTax.Label }}</div>
							<p class="m-0 u-height-42 d-flex align-items-center justify-content-end">{{ setPriceIncludingTax | currency }}</p>
						</div>
					</div>
				</div>

                <div class="mb-2">
                    <div 
                        :class="[{'mt-5' : selectedLegalCompanyId && legalCompanyDetails }, 'qa-accept-order-providers-responsibility-text']"
                    >
                        <h6 class="u-fw-semi-bold">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.Header }}</h6>
                        <p class="m-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.P1 }}</p>
                        <p class="m-0">{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.AcceptOrder.ProvidersResponsibility.P4 }}</p>
                    </div>
                </div>
            </template>

            <template slot="modal-footer">
                <div class="d-flex justify-content-end">
                    <recess-button variant="tertiary" 
                        :title="BUTTON_TEXT.cancel" 
                        class="qa-order-cancel-button" 
                        @click.native.prevent="close()" 
                    />
                    <recess-button 
                        variant="secondary" 
                        type="submit" 
                        :disabled="isValidForSubmit" 
                        :title="BUTTON_TEXT.submit" 
                        class="ml-3 qa-order-submit-button" 
                    />
                </div>
            </template>
        </recess-modal>
    </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { patchItem, postItem, getItems, getItemById } from '@/../../shared/api/SharedClient'
import { validationMixin } from 'vuelidate'
import { required, between } from 'vuelidate/lib/validators'

import { API_CALL_URL_PATHS, BUTTON_TEXT, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../../shared/services/UserService'

import { defaultErrorMessage, legalCompanyMissingCreditNumberError, 
	legalCompanyMissingVatNumberError, orderSuccessAction, isRequiredErrorMessage,
	invalidPercentageError } from '../../../../shared/constants/validationMessageHelper'
 

const LegalCompanyDetail = () => import('@/components/molecules/shared/LegalCompanyDetail')

export default {
	name: 'AcceptPurchaseAgreementPopupComponent',
	components: {
		LegalCompanyDetail
	},
	mixins: [validationMixin],
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},

		orderNumber: {
			type: String,
			default: '-'
		},
		providerId: {
			type: String,
			default: null
		} 
	},
	data() {
		return {
			PROVIDER,
			userService: new UserService(),
			isAdministrator: false,
			BUTTON_TEXT,
			isTouched: false,
			selectedLegalCompanyId: null,
			legalCompanyList: [],
			legalCompanyDetails: null,
			hasErrorPatchOrderLines: false, 
			setObjectToBePatched: null,
			countryOptions: [],
			selectedCountry: 'NL',
			countries: null,
			vatPercentageOptions: [],
			getVatAmount: 0,
			selectedVatPercentage: null,
			orderStatusTransitionItem: this.getEmptyOrderStatusTransition(),
		}
	},
	validations: {
		selectedCountry: { required },
		selectedVatPercentage: { 
			required,
			between: between(0, 100),
			is2DecimalPoints() {
				if(!this.selectedVatPercentage) return true

				const decimalPoints = new RegExp(/^\-?[0-9]+(?:\.[0-9]{1,2})?$/)
				
				return decimalPoints.test(this.selectedVatPercentage)
			}
		}
	},
	computed: {
		...mapState('orderModule', ['order']),
		...mapGetters('orderModule', ['getProviderReferenceId']),
		legalCompanyOptions() {
			if (this.legalCompanyList) {
				return this.legalCompanyList.map(item => {
					return {
						value: item.id,
						displayText: item.name
					}
				})
			}
			return null
		},
		legalCompanyFilter() {
			let filter = 'deleted eq false' 

			if(this.providerId) {
				filter += ` and providerId eq ${this.providerId}`
			}

			return filter
		},
		isLegalCompanyCreditNumberValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find(x => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.creditNumber !== null && legalCompany.creditNumber !== undefined
		},
		isLegalCompanyVatNumberValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find(x => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.vatNumber !== null && legalCompany.vatNumber !== undefined && legalCompany.vatNumber !== ''
		},
		hasOrderSubsidy() {
			return this.order?.subsidyDisplayValue
		},
		isValidForSubmit(){
			return !this.selectedLegalCompanyId || (this.hasStartmoments && !this.chosenStartmoment)
		},
		vatCountryError() {
			return isRequiredErrorMessage('vatCountry', 'Land', this.$v.selectedCountry)
		},
		vatPercentageError() {
			if (!this.$v.selectedVatPercentage.between || !this.$v.selectedVatPercentage.is2DecimalPoints) {
				return invalidPercentageError
			}
			
			return isRequiredErrorMessage('vatPercentage', 'BTW', this.$v.selectedVatPercentage)
		},
		setPriceIncludingTax() {
			return this.order?.orderLines[0].subtotalWithoutVAT + Number(this.getVatAmount)
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			// reset data status when modal is closed by watching the value
			// change of isModalvisible from its parent 'OrderList'
 
			if (newValue === false) {
				this.isTouched = false
				this.hasErrorPatchOrderLines = false

				if (this.legalCompanyList.length > 1) {
					this.legalCompanyDetails = null
					this.selectedLegalCompanyId = null
				}
			}

			if(newValue === true) { 
				this.getLegalCompanyList() 
				this.populateVatOptions('NL')
			}
		},
		providerId: {
			handler() {
				if (this.providerId) {
					this.getLegalCompanyList()
				}
			}
		},
		legalCompanyList: {
			handler() {
				if (this.legalCompanyList.length === 1) {
					this.legalCompanySelectHandler(this.legalCompanyList[0].id)
				}
			}
		}
	},
    async created() {
		this.getCountryListAndSetCountryTaxRates()
		this.isAdministrator = await this.userService.isAdministrator()
	},
	methods: {
		...mapActions('orderModule', ['patchOrder']),	
		close() {
			this.selectedVatPercentage = null
			this.getVatAmount = 0
			this.selectedCountry = 'NL'
			this.vatPercentageOptions = []  
			this.$v.$reset()

			this.$emit('close')
		},
		onTouch() {
			this.isTouched = true
		},
		legalCompanySelectHandler(newValue) {
			this.selectedLegalCompanyId = newValue
			this.getLegalCompanyDetails()
		},

		showManualNotification(type, propertyErrorMessage) {
			const toastNotification = {
				type,
				message: propertyErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},

		async submitHandler() {
			this.$v.$touch()
			if(this.$v.$invalid) return

			if (!this.isLegalCompanyCreditNumberValid) {
				this.showManualNotification('error', legalCompanyMissingCreditNumberError())
				return
			}

			if (!this.isLegalCompanyVatNumberValid) {
				this.showManualNotification('error', legalCompanyMissingVatNumberError)
				return
			}

			// patch order to include 'invoice on behalf of' information that allows order status change afterwards 
			for (const orderLine of this.order.orderLines) {
				await this.patchOrderLinesForEachSequenceNumber(this.order.id, orderLine.sequenceNumber)
			}

			// approve order status
			this.orderStatusTransitionItem.status = PROVIDER.Order.Shared.OrderStatus.AcceptedProvider

			if(this.order.status !== PROVIDER.Order.Shared.OrderStatus.AcceptedProvider) {
				await this.postOrderStatusTransition(this.order.id)			
			}

			this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
			this.close()
		},
		getOrdersAfterRebuildOrderIndex(millisecond) {
			this.timeout = setTimeout(() => this.$bus.emit('refresh-orders'), millisecond)
		},
		async postOrderStatusTransition(orderId) {

			await postItem(
					process.env.VUE_APP_ORDER_API_URL, 
					`${API_CALL_URL_PATHS.orders}/${orderId}/status-transitions`, 
					this.orderStatusTransitionItem, 
					false
				)
				.then(response => {
					if (!response) return 

					this.showManualNotification('success', orderSuccessAction('goedgekeurd')) 
				})
				.catch(error => { 
					this.showManualNotification('error', defaultErrorMessage)
 
					console.error('Something went wrong while posting order status', error)
				})
		},
		async getLegalCompanyList() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, 
					1, 
					99999, 
					'name asc', 
					this.legalCompanyFilter, 
					false
				)
				if (!response) return
				this.legalCompanyList = response.items
			} catch (error) {
				console.error('Something went wrong while retrieving legal company list', error)
			}
		},
		async getLegalCompanyDetails() {
			if (!this.selectedLegalCompanyId) return
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, this.selectedLegalCompanyId, null, false)
				if (!response) return
				this.legalCompanyDetails = response
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		}, 
		async patchOrderLinesForEachSequenceNumber(orderId, orderLineSequenceNumber) {
			const patchObjList = []

			const invoiceOnBehalfOfNameObj = {
				value: this.legalCompanyDetails.name,
				path: 'invoiceOnBehalfOfName',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfNameObj)

			const invoiceOnBehalfOfVatNumberObj = {
				value: this.legalCompanyDetails.vatNumber,
				path: 'invoiceOnBehalfOfVatNumber',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfVatNumberObj)

			const invoiceOnBehalfOfMerchantIdObj = {
				value: this.legalCompanyDetails.merchantId,
				path: 'invoiceOnBehalfOfMerchantId',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfMerchantIdObj)

			const vatPercentage = {
				value: this.selectedVatPercentage,
				path: 'vatPercentage',
				op: 'replace'
			}

			const vatCountry = {
				value: this.selectedCountry,
				path: 'vatCountry',
				op: 'replace'
			}

			patchObjList.push(vatPercentage, vatCountry)

			await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, `${orderId}/lines/${orderLineSequenceNumber}`, patchObjList, false).then(response => {
				if (!response) return
			})
		},
		getEmptyOrderStatusTransition() {
			return {
				status: null,
				cancellationReason: null,
				message: null
			}
		},
		async getCountryListAndSetCountryTaxRates() {
			try {
				const response = await getItems(
					process.env.VUE_APP_PROVIDER_API_URL, 
					API_CALL_URL_PATHS.countries, 
					1, 
					99999, 
					'sortOrder, name',
					null, 
					false
				)
				if (!response) return 
				 
				this.countries = response.items
				
				this.countries.reduce((acc, country) => {
				if (country) {
					this.countryOptions.push({
						displayText: country.displayName ? country.displayName : country.name,
						value: country.codeAlpha2
					})
				}
				return acc
				}, [])

				this.setVatOptions()
				
			} catch (error) {
				console.error('Something went wrong while retrieving country list', error)
			}
		},
		populateVatOptions(newValue) {
			this.selectedCountry = newValue
			this.selectedVatPercentage = null
			this.getVatAmount = 0
			this.setVatOptions()
		}, 
		async setVatOptions() {   
			const countryId = this.getSelectedCountryId(this.selectedCountry)
			const expandedProperty = 'taxRates'

			if (!countryId) return   
 
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.countries, countryId, expandedProperty, false)
				if (!response) return 
 
				const localTaxRates = response.taxRates.map((taxRateItem) => {
					return taxRateItem.taxRate
				})   
 
				this.setCountryAndVatPercentageOptions(localTaxRates)
  
			} catch (error) {
				console.error('Something went wrong while retrieving country details', error)
			}			 
		},
		getSelectedCountryId() {			
			if (this.countries.length === 0 || !this.selectedCountry) return null
			return this.countries.find((country) => country.codeAlpha2 === this.selectedCountry).id
		},
		setCountryAndVatPercentageOptions(localTaxRates) {
			if (this.selectedCountry) {
				this.vatPercentageOptions = []  

				// based on the selected country, recreate country-specific vatPercentageOptions for the BTW select dropdown in invoiceItem component
				localTaxRates.reduce((acc, taxRate) => {
					if (taxRate !== null) {
						const newOption = {
							displayText: `${taxRate}%`,
							value: taxRate
						}

						this.vatPercentageOptions.push(newOption)  
					}
					return acc
				}, [])
			}
		},
		onVatPercentageChange(newValue) {
			this.selectedVatPercentage = newValue 
            this.setVatAmount()
		},
		setVatAmount() {
			this.getVatAmount = ((this.selectedVatPercentage / 100) * this.order?.orderLines[0].subtotalWithoutVAT).toFixed(2).replace(/(\.0+|0+)$/, '')
		}
	}
}
</script>
